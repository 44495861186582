import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Paragraph, Box } from 'theme-ui';
import moment from 'moment-timezone';
import FormWrapper from 'components/common/FormWrapper';
import FormButton from 'components/common/FormButton';
import Alert from 'components/common/Alert';
import { Recaptcha } from 'components/common/Fields';
import { capitalize } from 'lib/util';

import {
  ReviewWrapper,
  ReviewRow,
  ReviewLabel,
  ReviewValue,
} from 'components/common/ReviewCard';

const Review = ({
  setStep,
  selectedSlot,
  submitError,
  selectedCentre,
  reasons,
  steps,
  setRecaptchaWidget,
}) => {
  const { values, isSubmitting, isValid } = useFormikContext();

  const centre = selectedCentre;

  const centreTitle = centre?.title;
  const centreAddress = centre?.details?.address;

  const patientType = values?.patientType;
  const service =
    reasons[patientType]?.find(r => r.value === values?.service)?.title ||
    values?.service;
  const practitioner = selectedSlot?.doctor?.doctorName;
  const date =
    selectedSlot?.start &&
    moment(selectedSlot?.start)
      .tz(selectedSlot?.timezone)
      .format('dddd, Do MMMM YYYY');
  const time = moment(selectedSlot?.start)
    .tz(selectedSlot?.timezone)
    .format('h:mma');

  return (
    <FormWrapper
      title="Review &amp; confirm"
      alert={
        <Alert variant="info">
          Please check all the details are correct before booking.
        </Alert>
      }
    >
      <ReviewWrapper title="Centre details">
        {centreTitle ? (
          <ReviewRow>
            <ReviewLabel text="Centre details" />
            <ReviewValue text={centreTitle} />
          </ReviewRow>
        ) : null}
        {centreAddress ? (
          <ReviewRow>
            <ReviewLabel text="Centre address" />
            <ReviewValue text={centreAddress} />
          </ReviewRow>
        ) : null}
      </ReviewWrapper>

      <ReviewWrapper
        title="Appointment details"
        onEditClick={() => setStep(steps[1].value)}
      >
        {patientType ? (
          <ReviewRow>
            <ReviewLabel text="Patient type" />
            <ReviewValue text={capitalize(patientType)} />
          </ReviewRow>
        ) : null}
        {service ? (
          <ReviewRow>
            <ReviewLabel text="Service" />
            <ReviewValue text={service} />
          </ReviewRow>
        ) : null}
        {practitioner ? (
          <ReviewRow>
            <ReviewLabel text="Practitioner" />
            <ReviewValue text={practitioner} />
          </ReviewRow>
        ) : null}
        {date ? (
          <ReviewRow>
            <ReviewLabel text="Date" />
            <ReviewValue text={date} />
          </ReviewRow>
        ) : null}
        {time ? (
          <ReviewRow>
            <ReviewLabel text="Time" />
            <ReviewValue text={time} />
          </ReviewRow>
        ) : null}
      </ReviewWrapper>

      <ReviewWrapper
        title="Your details"
        onEditClick={() => setStep(steps[3].value)}
      >
        {values?.title && values?.firstName && values?.lastName ? (
          <ReviewRow>
            <ReviewLabel text="Name" />
            <ReviewValue
              text={`${values.title} ${values.firstName} ${values.lastName}`}
            />
          </ReviewRow>
        ) : null}
        {values?.contactNumber ? (
          <ReviewRow>
            <ReviewLabel text="Phone number" />
            <ReviewValue text={values.contactNumber} />
          </ReviewRow>
        ) : null}
        {values?.email ? (
          <ReviewRow>
            <ReviewLabel text="Email" />
            <ReviewValue text={values.email} />
          </ReviewRow>
        ) : null}
        {values?.appointmentFor === 'Myself' &&
          (values?.dob ? (
            <ReviewRow>
              <ReviewLabel text="Date of birth" />
              <ReviewValue text={moment(values.dob).format('Do MMMM YYYY')} />
            </ReviewRow>
          ) : null)}
        {values?.appointmentFor === 'Someone else' &&
          (values?.relationship || values?.otherRelationship ? (
            <ReviewRow>
              <ReviewLabel text="Relationship to patient" />
              <ReviewValue
                text={
                  values.relationship === 'Other'
                    ? values.otherRelationship
                    : values.relationship
                }
              />
            </ReviewRow>
          ) : null)}
      </ReviewWrapper>

      {values.appointmentFor === 'Someone else' && (
        <ReviewWrapper
          title="Patient details"
          onEditClick={() => setStep(steps[3].value)}
        >
          {values?.patientTitle &&
          values?.patientFirstName &&
          values?.patientLastName ? (
            <ReviewRow>
              <ReviewLabel text="Name" />
              <ReviewValue
                text={`${values.patientTitle} ${values.patientFirstName} ${values.patientLastName}`}
              />
            </ReviewRow>
          ) : null}
          {values?.dob ? (
            <ReviewRow>
              <ReviewLabel text="Date of birth" />
              <ReviewValue text={moment(values.dob).format('Do MMMM YYYY')} />
            </ReviewRow>
          ) : null}
        </ReviewWrapper>
      )}

      <Paragraph variant="disclaimer" sx={{ marginTop: '32px' }}>
        By clicking on Confirm and Book you agree to us using the information in
        the booking form to manage your appointment and if you are booking on
        behalf of another patient have their consent to provide their details to
        us.
      </Paragraph>

      <Paragraph variant="disclaimer">
        By clicking on Confirm and Book you agree to make full payment on the
        day of service. If you have private health insurance with dental cover,
        please bring your health fund card to claim in centre. There may be some
        out of pocket expenses for some services like x-rays.
      </Paragraph>

      {process.env.NODE_ENV !== 'development' && (
        <Box sx={{ marginTop: '32px' }}>
          <Recaptcha setRecaptchaWidget={setRecaptchaWidget} />
        </Box>
      )}

      <FormButton
        type="submit"
        disabled={!isValid}
        text="Confirm &amp; Book"
        isSubmitting={isSubmitting}
        submitError={submitError}
      />
    </FormWrapper>
  );
};

Review.propTypes = {
  setStep: PropTypes.func.isRequired,
  selectedSlot: PropTypes.shape({
    doctor: PropTypes.shape({
      doctorName: PropTypes.string,
      doctorId: PropTypes.string,
    }),
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
  }),
  submitError: PropTypes.shape({
    message: PropTypes.string,
  }),
  selectedCentre: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  setRecaptchaWidget: PropTypes.func.isRequired,
};

export default Review;
