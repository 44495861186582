export const scrollToElement = (
  element = document?.getElementById('psd-booking-widget'),
  offset = 0
) => {
  if (!element) {
    return;
  }
  // handle scroll with iframe-resizer
  if ('parentIFrame' in window) {
    window?.parentIFrame.scrollToOffset(0, element?.offsetTop + offset);
    return;
  }

  window?.scrollTo({
    left: 0,
    top: element?.offsetTop,
    behavior: 'smooth',
  });
};

export default scrollToElement;
