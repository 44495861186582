import React from 'react';
import { Flex } from 'theme-ui';
import loader from 'images/loading.gif';

const Loader = ({ fullScreen, ...props }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: fullScreen ? '100vh' : '100%',
      }}
      ref={props?.innerRef}
      {...props}
    >
      <img
        src={loader}
        alt="Loading"
        width={props.width || '120'}
        height={props.height || '120'}
      />
    </Flex>
  );
};

export const Loader2 = ({ show, children, innerRef, ...props }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
      ref={innerRef}
      {...props}
    >
      {show && (
        <img
          src={loader}
          alt="Loading"
          width={props.width || '120'}
          height={props.height || '120'}
        />
      )}
      {!show && children}
    </Flex>
  );
};

export default Loader;
