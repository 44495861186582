import { useState, useMemo, useEffect } from 'react';
import { useSlots } from 'context/slotsContext';
import usePersistedCacheQuery from 'hooks/usePersistedCacheQuery';
import client from 'lib/client';

import { LocationSlotsQuery } from 'queries/centres.js';

const useSlotsQuery = values => {
  const [isPreloaded, setIsPreloaded] = useState(true);
  const { cachedQueries, centreData, practiceID, now, end } = useSlots();

  const getReasonIds = useMemo(() => {
    if (!values.service) {
      return [];
    }

    let doctors = centreData?.doctors || [];

    if (values.doctor && values.doctor !== 'any') {
      doctors = doctors.filter(doctor => values.doctor === doctor.doctorId);
    }

    return doctors
      .flatMap(doctor =>
        doctor?.doctorReasons
          .filter(r => r.reasonName === values.service)
          .map(r => parseInt(r.reasonId, 10))
      )
      .filter(Boolean);
  }, [centreData?.doctors, values?.doctor, values?.service]);

  const key = useMemo(() => {
    if (getReasonIds) {
      return getReasonIds.join(',');
    }
    return null;
  }, [getReasonIds]);

  useEffect(() => {
    setIsPreloaded(!!cachedQueries[key]);
  }, [cachedQueries, values.service, values.doctor, getReasonIds, key]);

  const { data, loading, error } = usePersistedCacheQuery(LocationSlotsQuery, {
    skip: isPreloaded || !values.service || !values.doctor,
    client,
    variables: {
      input: {
        practiceId: parseInt(practiceID, 10),
      },
      doctorId: values?.doctor
        ? values?.doctor === 'any'
          ? null
          : parseInt(values?.doctor, 10)
        : null,
      slotInput: {
        reasonList: getReasonIds || null,
        dateRange: {
          start: now.toISODate(),
          end: end.toISODate(),
        },
      },
    },
  });

  const slotData = useMemo(() => {
    if (cachedQueries[key]) {
      return cachedQueries[key];
    }
    return isPreloaded ? null : data;
  }, [cachedQueries, key, isPreloaded, data]);

  return {
    slotData,
    slotLoading: loading,
    slotError: error,
  };
};

export default useSlotsQuery;
