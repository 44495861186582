import React from 'react';
import { StandardText } from 'constants/typography';
import { browserName, isMobile } from 'react-device-detect';

const PhoneNumber = ({ phone, inline = false, render, children, ...props }) => {
  if (render) {
    return render({ phone });
  }
  return (
    <StandardText
      css={{
        textDecoration: 'none',
        display: inline ? 'inline-block' : 'block',
      }}
      clickable
      as="a"
      href={`tel:${phone}`}
      target={
        isMobile && browserName?.toLowerCase()?.includes('chrome')
          ? '_self'
          : '_top'
      }
      {...props}
    >
      {children || phone}
    </StandardText>
  );
};

export default PhoneNumber;
