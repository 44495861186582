import React, { useEffect, useState, useRef } from 'react';
import { SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import {
  GoogleMap,
  GoogleMarkerClusterer,
  Marker,
  InfoBox,
} from '@react-google-maps/api';
import { CentrePopup } from 'components/Map/Centre';
import { isMobile } from 'lib/util';

const Map = ({ loading, data, results, searchRadius, setCentre }) => {
  const mapRef = useRef(null);
  const mapBoundsRef = useRef({
    lat: -25.2744,
    lng: 133.7751,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = centre => {
    if (centre.id === activeMarker) {
      return;
    }

    setActiveMarker(centre.id);

    // Needed to make 1st infobox render to pan correctly
    setTimeout(() => {
      mapRef.current.panTo({
        lat: centre.latitude,
        lng: centre.longitude,
      });
    }, 0);
  };

  useEffect(() => {
    if (!mapRef?.current || loading) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    setActiveMarker(null);

    const allCentresLoaded = !!data?.centreSearch?.centres?.length;
    const searchResultsLoaded =
      allCentresLoaded && data?.centreSearch?.showResultsList;
    const searchResultsOutsideRadius =
      searchResultsLoaded &&
      data?.centreSearch?.centres?.[0]?.distance > searchRadius / 1000;

    let points = [];

    // Still loading - Show all of australia
    if (!allCentresLoaded) {
      points.push({ latitude: -25.2744, longitude: 133.7751 });
    }

    // Loaded all centres but no search results or search results are outside radius
    if (
      allCentresLoaded &&
      (!searchResultsLoaded || searchResultsOutsideRadius)
    ) {
      points = [...points, ...data?.centreSearch?.centres];
    }

    // Loaded search results and they're within the radius
    if (searchResultsLoaded && !searchResultsOutsideRadius) {
      points = [...points, ...data?.centreSearch?.centres?.slice(0, 10)];
    }

    // Include users location in points
    if (results) {
      points.push(results);
    }

    points.forEach(point =>
      bounds.extend(
        new window.google.maps.LatLng(point.latitude, point.longitude)
      )
    );

    // Uses the current map position if new search returns no centres
    // mapBoundsRef.current = bounds.getCenter();

    mapRef.current.fitBounds(bounds);
    mapRef.current.panToBounds(bounds);
  }, [mapRef?.current, loading, data, results]);

  const onLoad = map => {
    mapRef.current = map;
  };

  const clusterOptions = {
    // algorithm: new SuperClusterAlgorithm({
    //   maxZoom: 10,
    // }),
    renderer: {
      render: ({ count, position }) =>
        new window.google.maps.Marker({
          label: {
            text: String(count),
            color: 'white',
            fontSize: '12px',
            fontWeight: 'bold',
          },
          position,
          // adjust zIndex to be above other markers
          zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        }),
    },
  };

  return (
    <div style={{ width: '100%', height: '100%' }} id="map">
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        center={mapBoundsRef?.current}
        zoom={5}
        onLoad={onLoad}
        options={{
          streetViewControl: false,
        }}
      >
        {mapRef?.current && data?.centreSearch?.centres?.length && (
          <GoogleMarkerClusterer options={clusterOptions}>
            {clusterer => {
              const points = data?.centreSearch?.centres?.map(centre => (
                <Marker
                  clusterer={clusterer}
                  key={centre.id}
                  position={{
                    lat: centre.latitude,
                    lng: centre.longitude,
                  }}
                  zIndex={120}
                  onClick={() => handleActiveMarker(centre)}
                >
                  {activeMarker === centre.id ? (
                    <InfoBox
                      options={{
                        boxStyle: { backgroundColor: '#fff' },
                        // isHidden: activeMarker !== centre.id,
                        // visible: activeMarker === centre.id,
                        closeBoxURL: '',
                        enableEventPropagation: true,
                        disableAutoPan: true,
                        pixelOffset: isMobile
                          ? { width: -175, height: 6 }
                          : { width: 0, height: 0 },
                      }}
                    >
                      <div
                        style={{
                          padding: '16px 12px',
                          width: '350px',
                        }}
                      >
                        <CentrePopup
                          centre={centre}
                          setCentre={setCentre}
                          searchDetails={results}
                          onClose={() => setActiveMarker(null)}
                        />
                      </div>
                    </InfoBox>
                  ) : null}
                </Marker>
              ));

              if (results) {
                points.push(
                  <Marker
                    key="user"
                    position={{
                      lat: results.latitude,
                      lng: results.longitude,
                    }}
                    zIndex={100}
                    icon={{
                      path: 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z',
                      fillColor: '#255EC1',
                      fillOpacity: 1,
                      strokeWeight: 0,
                      rotation: 0,
                      scale: 2,
                      anchor: new google.maps.Point(12, 22),
                    }}
                  />
                );
              }

              return points;
            }}
          </GoogleMarkerClusterer>
        )}
      </GoogleMap>
    </div>
  );
};

export default React.memo(Map);
