import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled/macro';
import { StandardText } from 'constants/typography';
import TickIcon from 'icons/tick.svg';

const StepNumber = styled.button`
  appearance: none;
  padding: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  border: solid 1px #255ec1;
  color: #255ec1;
  font-size: 0;
  line-height: 0;
  text-align: center;
  position: relative;
  flex-shrink: 0;

  img {
    display: none;
    top: 4px;
    position: relative;
  }

  &[data-active='true'] {
    background-color: #255ec1;
    color: white;
    border-color: transparent;
  }

  ${props => props.theme.mq.mdInverted} {
    &[data-complete='true'] {
      background-color: #255ec1;
      color: white;
      border-color: transparent;
    }
  }

  ${props => props.theme.mq.md} {
    margin: auto 21px auto 0;
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;

    img {
      display: inline;
      top: 4px;
      position: relative;
    }
  }
`;

const StepGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;

  ${props => props.theme.mq.md} {
    flex-direction: column;
    width: 100%;
    align-items: start;
  }

  &:last-of-type {
    ${props => props.theme.mq.mdInverted} {
      width: max-content;
      position: relative;
    }
  }
`;

const Step = styled(Flex)`
  &:last-of-type {
    margin-bottom: 0;

    div {
      &:after {
        display: none;
      }
    }
  }
`;

const StepContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: -15px;

  ${props => props.theme.mq.md} {
    flex-direction: column;
    margin-right: 0;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border: solid 0.5px #255ec1;
  padding: 0 15px;

  ${props => props.theme.mq.md} {
    left: 0;
    position: relative;
    height: 26px;
    width: 1px;
    padding: 0;
    margin: 6px 15px;
  }
`;
const PipText = styled(StandardText)`
  letter-spacing: 0.04rem;

  ${props => props.theme.mq.mdInverted} {
    display: none;
  }
`;

const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: center;
  flex-flow: column nowrap;
  margin-bottom: 24px;

  ${props => props.theme.mq.md} {
    min-width: 222px;
    width: 222px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row nowrap;
    margin-bottom: 0;
  }

  @media (max-width: 991.98px) {
    margin: -24px -15px 0 -15px;
    padding: 12px 15px;
    background-color: white;
    box-sizing: content-box;
  }

  @media (min-width: 992px) {
    margin-top: 92px;
  }
`;

const ProgressBar = ({ data, currentStep, setStep }) => {
  const enabledSteps = data.steps.filter(d => d.enabled);

  return (
    <Wrapper>
      <StepContainer>
        {enabledSteps.map((stepNumber, index) => (
          <StepGroup key={stepNumber.label}>
            <Step
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <StepNumber
                data-active={currentStep === stepNumber.value}
                data-complete={stepNumber.value < currentStep}
                onClick={() => {
                  if (stepNumber.value < currentStep) {
                    setStep(stepNumber.value);
                  }
                }}
                disabled={stepNumber.value >= currentStep}
              >
                {stepNumber.value < currentStep ? (
                  <img src={TickIcon} alt="tick" />
                ) : (
                  index + 1
                )}
              </StepNumber>
              <PipText
                width="175px"
                fontSize="16px"
                lineHeight="24px"
                color="#202938"
                bold={currentStep === stepNumber.value}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (stepNumber.value < currentStep) {
                    setStep(stepNumber.value);
                  }
                }}
              >
                {stepNumber.label}
              </PipText>
            </Step>
            {index < enabledSteps.length - 1 && <Line />}
          </StepGroup>
        ))}
      </StepContainer>
    </Wrapper>
  );
};

export default ProgressBar;
