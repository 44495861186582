import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { DateTime } from 'luxon';
import client from 'lib/client';

import { LocationSlotsQuery } from 'queries/centres.js';

const SlotsContext = createContext();

let storedData = {};

export const SlotsProvider = ({
  reasonMappings,
  centreData,
  centre,
  children,
  isEmergencyAppointmentMode,
}) => {
  const now = useMemo(() => DateTime.local(), []);
  const end = useMemo(
    () => now?.plus({ days: isEmergencyAppointmentMode ? 30 : 90 }),
    [now, isEmergencyAppointmentMode]
  );

  const [practiceID, setPracticeID] = useState(centre?.practiceID);
  const [cachedQueries, setCachedQueries] = useState({});
  // if the practiceID changes, reset the slots data
  useEffect(() => {
    if (centre?.practiceID !== practiceID) {
      storedData = {};
      setCachedQueries({});
      setPracticeID(centre?.practiceID);
    }
  }, [centre, practiceID]);

  const storeCachedValue = (key, data) => {
    if (!data) {
      return;
    }
    if (!storedData[key]) {
      storedData[key] = data;
      setCachedQueries({ ...storedData });

      setTimeout(() => {
        storedData = {};
        setCachedQueries({});
      }, 10 * 60 * 1000);
    }
  };

  const reasonsToPreload = useMemo(() => {
    if (!reasonMappings) {
      return [];
    }

    const {
      existing,
      newSpecialist,
      existingSpecialist,
      new: newReasons,
    } = reasonMappings.reasonMapping;

    return [existing, newSpecialist, existingSpecialist, newReasons]
      .flat()
      .filter(reason => reason.preloadBookingSlots)
      .map(reason => reason.value);
  }, [reasonMappings]);

  useEffect(() => {
    if (!centreData?.doctors) {
      return;
    }

    reasonsToPreload.forEach(async reason => {
      const reasonIds = centreData?.doctors
        .map(d => d.doctorReasons?.find(r => r.reasonName === reason)?.reasonId)
        .filter(Boolean)
        .map(id => parseInt(id, 10));

      const { data } = await client.query({
        query: LocationSlotsQuery,
        skip: !reasonIds,
        variables: {
          input: {
            practiceId: parseInt(practiceID, 10),
          },
          doctorId: null,
          slotInput: {
            reasonList: reasonIds || null,
            dateRange: {
              start: now.toISODate(),
              end: end.toISODate(),
            },
          },
        },
      });
      const key = reasonIds?.join(',');
      storeCachedValue(key, data);
    });
  }, [reasonsToPreload]);

  return (
    <SlotsContext.Provider
      value={{
        cachedQueries,
        centre,
        centreData,
        practiceID,
        now,
        end,
      }}
    >
      {children}
    </SlotsContext.Provider>
  );
};

export const useSlots = () => useContext(SlotsContext);
