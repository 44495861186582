export const sendGoogleActionConversion = async (practiceID) => {
  const partnerId = process.env.REACT_APP_GOOGLE_ACTION_CONVERSION_PARTNER_ID;
  const endpoint = process.env.REACT_APP_GOOGLE_ACTION_URL;

  const rwgTokenCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('rwg_token='));

  const storedMerchant = document.cookie
    .split('; ')
    .find(row => row.startsWith('stored_merchant='));

  if (typeof rwgTokenCookie !== 'undefined') {
    const rwgTokenVal = rwgTokenCookie.substring('rwg_token='.length);
    const merchantVal = storedMerchant.substring('stored_merchant='.length);
    const merchantChanged = practiceID === merchantVal ? 2 : 1;
    fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({
        conversion_partner_id: parseInt(partnerId, 10),
        rwg_token: rwgTokenVal,
        merchant_changed: merchantChanged
      })
    });
  }
};


export const storeRwgToken = async (rwgToken, merchantId) => {
  // store rwgToken and merchantId in cookies for 30 days
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  setCookie("rwg_token", rwgToken, 30);
  setCookie("stored_merchant", merchantId, 30);
}
