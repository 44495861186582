import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react';
import { useThemeUI } from 'theme-ui';
import { Heading, Text as RebassText } from 'rebass';
import css from '@emotion/css';

const SMALL_FONT_SIZE = '13px';

// it is the same as H3 but without text being bold
let InfoTextAsH2 = styled.div`
  color: ${props => props.color || props.theme.colors.light};
  font-size: 21px;
  line-height: 26px;
  margin-bottom: ${props => props.marginBottom}px;
`;

InfoTextAsH2 = withTheme(InfoTextAsH2);

export { InfoTextAsH2 };

const StyledSmall = styled.div`
  font-size: ${SMALL_FONT_SIZE};
  color: ${props => props.color || '#000'};
`;

export const SmallStyle = props => css`
  font-size: ${SMALL_FONT_SIZE};
  color: ${props.color};
`;

export const H1 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h1'} fontSize={{ xs: 4, sm: 5 }} {...props}>
      {children}
    </Heading>
  );
};

export const H2 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h2'} fontSize={3} {...props}>
      {children}
    </Heading>
  );
};

export const H3 = withTheme(
  ({ theme, type, children, color, className, ...props }) => {
    return (
      <Heading
        as={type ? type : 'h3'}
        className={className}
        color={color ? color : theme.colors.text.dark}
        {...props}
      >
        {children}
      </Heading>
    );
  }
);

export const H4 = ({ type, children, ...props }) => {
  return (
    <Heading as={type ? type : 'h4'} fontSize={2} {...props}>
      {children}
    </Heading>
  );
};

export const Small = withTheme(({ theme, type, color, children }) => {
  return (
    <StyledSmall
      color={color ? color : theme.colors.text.light}
      as={type ? type : 'small'}
    >
      {children}
    </StyledSmall>
  );
});

export const P = ({ type, children, ...props }) => {
  return (
    <RebassText
      as={type ? type : 'p'}
      fontSize={2}
      lineHeight="24px"
      {...props}
    >
      {children}
    </RebassText>
  );
};

const textStyles = props => css`
  word-break: break-word;
  letter-spacing: 1px;
  line-height: ${props?.lineHeight || 21}px;
  color: ${props.color
    ? props?.theme?.colors?.text[props.color] || props?.color
    : props?.theme?.colors?.text?.light};
  font-size: ${props?.fontSize
    ? props?.theme?.fontSizes[props?.fontSize] || `${props?.fontSize}px`
    : props?.theme?.fontSizes[2]}px;
  font-weight: ${props?.bold ? 'bold' : 'normal'};
  font-family: ${props?.fontFamily
    ? `"${props?.fontFamily}" !important`
    : '"New Spirit Web" !important'};
`;

export const StandardHeading = styled(Heading)`
  ${props =>
    textStyles({
      ...props,
      fontFamily: 'New Spirit Web',
      fontSize: props.fontSize || 32,
      bold: props.bold || true,
    })};
`;

// TODO: reuse these for standard text as well
const StandardTextBase = styled(RebassText)`
  word-break: break-word;
  cursor: ${props => (props.clickable ? 'pointer' : 'inherit')};
  line-height: ${props => props.lineHeight || '21px'};
  color: ${props =>
    props.color
      ? props.theme.colors[props.color] || props.color
      : props.theme.colors.text.light};
  font-size: ${props =>
    props.fontSize
      ? props.theme.fontSizes[props.fontSize] || `${props.fontSize}px`
      : props.theme.fontSizes[2]}px;
  font-weight: ${props => (props.bold ? 'bold' : '')};
  flex-shrink: 0;
  text-align: ${props => props.textAlign || 'left'};
  display: ${props => (props.inline ? 'inline' : 'block')};
  font-family: 'Smiles Web', 'Microsoft Sans Serif', sans-serif !important;
  em {
    font-style: italic;
  }
`;

const TextResponsiveBase = styled(StandardTextBase)`
  text-align: center;
  ${props => props.theme.mq.md} {
    text-align: left;
  }
`;

export const TextResponsive = withTheme(TextResponsiveBase);

export const StandardText = props => {
  const { theme } = useThemeUI();
  return <StandardTextBase theme={theme} {...props} />;
};
