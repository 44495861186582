import React from 'react';
import { useThemeUI, Box } from 'theme-ui';

const ErrorIcon = ({ className, width = '20px', sx }) => {
  const { theme } = useThemeUI();
  return (
    <Box
      as="svg"
      height="18"
      viewBox="0 0 20 18"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      sx={{
        width: width,
        minWidth: width,
        ...sx,
      }}
    >
      <path
        d="m19.7561576 21.8413626-8.7175274-15.09922966c-.5711382-.98951059-1.50601447-.98951059-2.0773628 0l-8.71752731 15.09922966c-.5711382.9897207-.10380511 1.7991484 1.03889155 1.7991484h17.43505466c1.1422764 0 1.6098196-.8094277 1.0384713-1.7991484zm-9.7301524-.9117618h-.0130282c-.56042148 0-.97753308-.4166913-.97753308-1.0294356 0-.5862677.4171116-1.0164075.99056128-1.0164075.5734496 0 .9905612.4301398.9905612 1.0164075 0 .6127443-.4171116 1.0294356-.9905612 1.0294356zm.9123922-7.310695c-.2475353 1.3944345-.443168 2.8018972-.6385905 4.300347-.0390845.1563381-.57344966.1697865-.59971614 0-.19521243-1.4984498-.40366316-2.9059125-.65161867-4.300347-.07816902-.4950705-.11725354-.7299978-.11725354-.9512666 0-.3128862.22168904-.5604215 1.06873027-.5604215h.01281808c.8081668 0 1.0557027.2475353 1.0557027.5604215.0002095.2212688-.0519032.4561961-.1300722.9512666z"
        fill={theme.colors.error}
        transform="translate(0 -6)"
      />
    </Box>
  );
};

export default ErrorIcon;
