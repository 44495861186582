import React from 'react';
import { Input as ThemeInput, Box, Label, Flex, Paragraph } from 'theme-ui';
import { Field, useFormikContext } from 'formik';

const Input = ({
  name,
  label,
  type = 'text',
  optional = false,
  placeholder,
  help,
  labelProps,
  ...props
}) => {
  const { touched, errors, values } = useFormikContext();

  return (
    <Box>
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...labelProps,
        }}
      >
        <Label htmlFor={name}>{label}</Label>
        {optional && <Paragraph variant="optional">(Optional)</Paragraph>}
      </Flex>
      <Field
        as={ThemeInput}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        {...props}
      />
      {help && <Paragraph variant="help">{help}</Paragraph>}
      {(touched?.[name] || values?.[name]) && errors?.[name] && (
        <Paragraph variant="error">{errors?.[name]}</Paragraph>
      )}
    </Box>
  );
};

export default Input;
