import React from 'react';
import { Paragraph } from 'theme-ui';
import SuccessIcon from 'components/common/Icons/SuccessIcon';
import FormWrapper from 'components/common/FormWrapper';
import { FieldWrapper, FieldHeading } from 'components/common/FieldCard';

const Success = ({ selectedCentre, email }) => {
  return (
    <FormWrapper>
      <FieldWrapper alignItems="center" textAlign="center">
        <FieldHeading>Your appointment is booked</FieldHeading>
        <Paragraph sx={{ textAlign: 'center' }}>
          We'll send you a confirmation email for your booking to {email}
        </Paragraph>
        <SuccessIcon
          sx={{
            minWidth: '96px',
            marginTop: '32px',
          }}
        />
        <Paragraph sx={{ marginTop: '32px', textAlign: 'center' }}>
          If you haven't received your confirmation email, please check your
          spam. Otherwise contact Pacific Smiles Dental {selectedCentre?.title}
          {selectedCentre?.details?.phone &&
            ` on ${selectedCentre?.details?.phone}`}{' '}
          to check your booking.
        </Paragraph>

        <Paragraph sx={{ marginTop: '32px', textAlign: 'center' }}>
          You will receive a call or SMS from the centre to confirm your
          appointment. Please ensure you respond, as a non-confirmation may
          result in the rescheduling of your appointment due to limited
          appointment availability.
        </Paragraph>
      </FieldWrapper>
    </FormWrapper>
  );
};

export default Success;
