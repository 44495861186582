import { Box, useThemeUI } from 'theme-ui';

const SuccessIcon = ({ width, color, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 96"
      fill="none"
      sx={{
        minWidth: width || '96px',
        width: width || '96px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <path
        d="M48 0C38.5065 0 29.2262 2.81515 21.3326 8.08946C13.4391 13.3638 7.28681 20.8603 3.6538 29.6312C0.0207977 38.402 -0.929762 48.0532 0.922328 57.3643C2.77442 66.6754 7.34598 75.2282 14.0589 81.9411C20.7718 88.654 29.3246 93.2256 38.6357 95.0777C47.9468 96.9298 57.598 95.9792 66.3688 92.3462C75.1397 88.7132 82.6363 82.5609 87.9106 74.6674C93.1849 66.7738 96 57.4935 96 48C96 35.2696 90.9429 23.0606 81.9411 14.0589C72.9394 5.05713 60.7304 0 48 0V0ZM75.72 32.8L48.32 69.96C48.0012 70.3945 47.5978 70.7601 47.1342 71.0348C46.6706 71.3095 46.1562 71.4877 45.622 71.5587C45.0877 71.6297 44.5447 71.592 44.0254 71.4479C43.5061 71.3037 43.0212 71.0562 42.6 70.72L23.04 55.08C22.6291 54.7499 22.2875 54.3419 22.0348 53.8793C21.7821 53.4168 21.6233 52.9088 21.5676 52.3847C21.5118 51.8606 21.5602 51.3307 21.7099 50.8253C21.8596 50.3199 22.1078 49.8492 22.44 49.44C23.1047 48.6334 24.0586 48.1191 25.0977 48.0069C26.1369 47.8948 27.1786 48.1938 28 48.84L44.32 61.88L69.28 28C69.9098 27.1566 70.8467 26.5954 71.8875 26.4382C72.9283 26.2809 73.9892 26.5403 74.84 27.16C75.2721 27.4694 75.6382 27.8618 75.9168 28.3143C76.1955 28.7668 76.3813 29.2703 76.4632 29.7954C76.5451 30.3205 76.5216 30.8566 76.394 31.3725C76.2664 31.8884 76.0373 32.3737 75.72 32.8Z"
        fill={color || theme.colors.sydneySky}
      />
    </Box>
  );
};

export default SuccessIcon;
