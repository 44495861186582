import { Box, useThemeUI } from 'theme-ui';

const InfoIcon = ({ width, color, backgroundColor, sx }) => {
  const { theme } = useThemeUI();

  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill={color || theme.colors.primary}
      sx={{
        minWidth: width || '20px',
        width: width || '20px',
        pointerEvents: 'none',
        ...sx,
      }}
    >
      <path
        d="M19.25 10C19.25 15.1086 15.1086 19.25 10 19.25C4.89137 19.25 0.75 15.1086 0.75 10C0.75 4.89137 4.89137 0.75 10 0.75C15.1086 0.75 19.25 4.89137 19.25 10Z"
        stroke={color || theme.colors.primary}
        strokeWidth="1.5"
      />
      <line
        x1="0.75"
        y1="-0.75"
        x2="7.82143"
        y2="-0.75"
        transform="matrix(1.5299e-07 1 1 -1.12401e-07 10.75 7.29999)"
        stroke={backgroundColor || theme.colors.blue200}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.0001 4.87499C9.95868 4.87499 9.9251 4.90857 9.9251 4.94999C9.9251 4.99141 9.95868 5.02499 10.0001 5.02499C10.0415 5.02499 10.0751 4.99141 10.0751 4.94999C10.0751 4.90857 10.0415 4.87499 10.0001 4.87499V4.87499"
        stroke={backgroundColor || theme.colors.blue200}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
};

export default InfoIcon;
