import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  from,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { isRunningOnClientSide } from 'lib/util';
import { customFetch } from 'lib/customFetch';

const { createUploadLink } = require('apollo-upload-client');

const getUri = () => {
  if (process.env.REACT_APP_GRAPHQL_URI) {
    return process.env.REACT_APP_GRAPHQL_URI;
  }

  if (isRunningOnClientSide()) {
    return '/graphql';
  }

  return `http://localhost:9000/graphql`;
};

// Saves the request id from the response so it can be resent with future requests
// const requestIdLink = new ApolloLink((operation, forward) => {
//   if (isRunningOnServerSide()) {
//     return forward(operation);
//   }

//   return forward(operation).map(response => {
//     const context = operation.getContext();
//     const {
//       response: { headers },
//     } = context;

//     // if (headers && sessionStorage) {
//     //   const requestId = headers.get('x-request-id');
//     //   if (requestId) {
//     //     sessionStorage?.setItem('REQ_ID', requestId);
//     //   }
//     // }

//     return response;
//   });
// });

// const authLink = setContext(async (request, prevContext) => {
//   if (isRunningOnServerSide()) {
//     return {};
//   }

//   const authHeaders = { ...prevContext.headers };

//   try {
//     const session = await Auth.currentSession();
//     const token = session.getAccessToken().getJwtToken();

//     if (token) {
//       authHeaders.authorization = `Bearer ${token}`;
//     }

//     if (!authHeaders['x-request-id']) {
//       let REQ_ID = sessionStorage.getItem('REQ_ID');
//       if (!REQ_ID) {
//         REQ_ID = uuidv4();
//       }
//       authHeaders['x-request-id'] = REQ_ID;
//     }
//   } catch (err) {}

//   return { headers: authHeaders };
// });

const httpLink = split(
  operation => operation.getContext().hasUpload,
  createUploadLink({
    uri: getUri(),
    fetch: customFetch,
    credentials: 'same-origin',
    fetchOptions: {
      credentials: 'same-origin',
    },
  }),
  new BatchHttpLink({
    uri: getUri(),
    batchMax: 1, // essentially disabled
    batchInterval: 10,
    fetch: customFetch,
    includeExtensions: true,
    credentials: 'same-origin',
    fetchOptions: {
      credentials: 'same-origin',
    },
  })
);

const errorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      if (message !== 'PersistedQueryNotFound') {
        console.warn(
          `[GraphQL error] ${message} ${path ? `(${path.join(' -> ')})` : ''})`
        );
      }
    });
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
};

const wafLink = setContext(async (request, prevContext) => {
  const wafHeaders = { ...prevContext.headers };

  try {
    if (typeof window !== 'undefined' && window.AwsWafIntegration?.getToken) {
      wafHeaders['x-aws-waf-token'] = await window.AwsWafIntegration.getToken();
    }
  } catch (err) {}

  return { headers: wafHeaders };
});

const client = new ApolloClient({
  name: 'HBF Website',
  version: process.env.ENGINE_SCHEMA_TAG || 'dev',
  cache: new InMemoryCache({
    addTypename: true,
    dataIdFromObject: defaultDataIdFromObject,
    // possibleTypes,
  }),
  // ssrMode: isRunningOnServerSide(),
  link: from([
    // authLink,
    // requestIdLink,
    // createPersistedQueryLink(),
    wafLink,
    onError(errorHandler),
    httpLink,
  ]),
});

export default client;
