import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Flex, Box } from 'theme-ui';
import slugify from 'slugify';

const Radio = ({ name, label, choices, ...props }) => {
  const { values } = useFormikContext();

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }}
    >
      {label && (
        <Box as="legend" sx={{ marginBottom: '24px' }}>
          {label}
        </Box>
      )}
      <Flex
        sx={{
          flexDirection: 'row',
          flexWrap: 'nowrap',
        }}
      >
        {choices?.map(choice => (
          <Box
            as="label"
            sx={{
              color: 'text',
              backgroundColor: 'transparent',
              fontSize: 2,
              padding: '8px 16px',
              cursor: 'pointer',
              position: 'relative',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'grey400',
              borderLeftWidth: '0',
              '&:first-of-type': {
                borderLeftWidth: '1px',
                borderRadius: '4px 0 0 4px',
              },
              '&:last-of-type': {
                borderRadius: '0 4px 4px 0',
              },
              '&[data-active="true"]': {
                color: 'white',
                backgroundColor: 'primary',
                borderColor: 'primary',
              },
              input: {
                appearance: 'none',
                backgroundColor: 'transparent',
                position: 'absolute',
                top: '0',
                left: '0',
                margin: '0',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              },
            }}
            key={choice?.value}
            htmlFor={name}
            data-active={values?.[name] === choice?.value}
          >
            <Field
              id={slugify(`${name}-${choice?.value}`)}
              name={name}
              type="radio"
              value={choice?.value}
              {...props}
            />
            {choice?.label}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default Radio;
