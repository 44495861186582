import { darken, lighten } from 'polished';
import breakpoints from 'lib/breakpoints';

const bps = breakpoints;
const mq = {};
Object.keys(bps).forEach(bp => {
  mq[bp] = `@media (min-width: ${bps[bp]})`;
  mq[`${bp}Inverted`] = `@media (max-width: ${bps[bp]})`;
});

const defaultButton = {
  fontSize: 3,
  borderRadius: '4px',
  borderWidth: '2px',
  borderColor: 'transparent',
  borderStyle: 'solid',
  padding: '10px 24px',
  transition:
    '0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s color ease-in-out',
  cursor: 'pointer',
  '&:focus': {
    outlineOffset: '3px',
  },
};

const defaultAlert = {
  fontSize: 2,
  fontWeight: 'normal',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: '4px',
  color: 'white',
  backgroundColor: 'transparent',
  paddingInline: ['16px', '24px'],
  paddingBlock: ['12px', '18px'],
};

const theme = {
  mq,
  breakpoints: ['576px', '768px', '992px', '1200px'],
  sizes: {
    container: '966px',
  },
  layout: {
    container: {
      maxWidth: ['100%', '540px', '720px', '960px', '1625px'],
      px: '15px',
    },
    offsetContainer: {
      maxWidth: '960px',
      px: '15px',
    },
    bannerContainer: {
      maxWidth: ['100%', '540px', '720px', '960px', '1625px'],
      px: '15px',
    },
  },
  fonts: {
    sans: '"Smiles Web", "Helvetica Neue", Helvetica, Arial, sans-serif',
    body: '"Smiles Web", "Helvetica Neue", Helvetica, Arial, sans-serif',
    heading: '"New Spirit Web", "Helvetica Neue", Helvetica, Arial, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 36, 48, 60],
  fontWeights: {
    body: 400,
    heading: 500,
    light: 300,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.375,
  },
  colors: {
    text: '#202938',
    background: '#F9FAFC',
    primary: '#255EC1',
    grey200: '#F3F4F6',
    grey400: '#D2D5DC',
    grey500: '#9CA2AE',
    grey600: '#6B7380',
    grey700: '#4C5564',
    grey1000: '#111828',
    blue200: '#DCE6F8',
    sydneySky: '#7CA2DB',
    error: '#D0021B',
    errorLight: lighten(0.5, '#D0021B'),
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    disclaimer: {
      variant: 'paragraph',
      fontSize: 1,
      color: 'grey600',
    },
    error: {
      variant: 'paragraph',
      fontSize: [0, 1, 1],
      color: 'error',
      marginTop: '8px',
    },
    optional: {
      variant: 'paragraph',
      color: 'grey600',
    },
    help: {
      variant: 'paragraph',
      fontSize: [0, 1, 1],
      color: 'grey600',
      marginTop: '8px',
    },
    header: {
      variant: 'paragraph',
      fontSize: 2,
      fontWeight: 'bold',
      color: 'white',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      lineHeight: 1.25,
      fontWeight: 700,
      fontSize: [8, 9],
      color: 'white',
    },
    h2: {
      variant: 'text.heading',
      lineHeight: 1.25,
      fontWeight: 700,
      fontSize: ['28px', 8],
      color: 'primary',
    },
    h3: {
      variant: 'text.heading',
      fontSize: 6,
    },
  },
  alerts: {
    info: {
      ...defaultAlert,
      color: 'primary',
      bg: 'blue200',
      borderColor: 'sydneySky',
    },
    error: {
      ...defaultAlert,
      color: 'error',
      bg: 'errorLight',
      borderColor: 'error',
    },
  },
  forms: {
    label: {
      fontSize: 2,
      marginBottom: '8px',
      width: 'inherit',
    },
    select: {
      fontSize: 2,
      backgroundColor: 'white',
      borderColor: 'grey400',
      padding: '12px',
      '&::placeholder': {
        color: 'grey600',
      },
    },
    input: {
      fontSize: 2,
      backgroundColor: 'white',
      borderColor: 'grey400',
      padding: '12px',
      '&::placeholder': {
        color: 'grey600',
      },
      '&[type="date"]': {
        fontFamily: 'body',
        minHeight: '50px',
        '::-webkit-date-and-time-value': {
          textAlign: 'left',
        },
      },
    },
    textarea: {
      fontSize: 2,
      fontFamily: 'body',
      backgroundColor: 'white',
      borderColor: 'grey400',
      padding: '12px',
      '&::placeholder': {
        color: 'grey600',
      },
    },
  },
  buttons: {
    primary: {
      ...defaultButton,
      color: 'white',
      bg: 'primary',
      borderColor: 'primary',
      '&:hover, &:focus': {
        bg: darken(0.1, '#255EC1'),
        borderColor: darken(0.1, '#255EC1'),
      },
      '&:disabled': {
        bg: 'grey400',
        borderColor: 'grey400',
        cursor: 'not-allowed',
      },
    },
    minimal: {
      appearance: 'none',
      border: 'none',
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
};

export default theme;
